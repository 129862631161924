<template>
  <b-tr>
    <b-td>
      {{plafondTypeComputed}}
    </b-td>
    <b-td>
      {{usageFormatted}}
    </b-td>
    <b-td>
      {{plafondFormatted}}
    </b-td>
    <b-td>
      {{percentUsage}}
    </b-td>
  </b-tr>
</template>

<script>
import { formatCurrency, formatPercent } from '@/utils/formatterHelper';

export default {
  name: 'SingleTypePlafondUsage',
  props: {
    type: String,
    plafond: {
      totalPlafond: Number,
      usage: Number,
    },
  },
  computed: {
    plafondFormatted() {
      return formatCurrency(this.plafond.totalPlafond, 'it', 'EUR');
    },
    usageFormatted() {
      return formatCurrency(this.plafond.usage, 'it', 'EUR');
    },
    percentUsage() {
      const { usage, totalPlafond } = this.plafond;
      return formatPercent(usage / totalPlafond, 'it');
    },
    plafondTypeComputed() {
      let val = 'N.D.';
      const types = this.$store.getters['tableUtils/getPlafondTypes'];
      if (types.length > 0) {
        const typeFound = types.filter(type => type.key === this.type);
        val = typeFound[0].label;
      }
      return val;
    },
  },
};
</script>

<style scoped>

</style>
